<template>
  <div class="edito">
    <img width="100%" src="../assets/images/bdform.png" />
    <div class="mentions" v-html="contact" />
  </div>
</template>
<script>
export default {
  name: 'Contact',
  data() {
    return {
      contact : window.contact
    };
  }  
}
</script>